@import './colors.scss';

ion-button {
  --border-radius: 8px;
  --ripple-color: transparent;
  --box-shadow: none;
  font-family: 'MoriGothic-Bold';
  text-transform: capitalize;
}

.sc-ion-buttons-md-s .button-has-icon-only.button-clear {
  --border-radius: 8px;
}

.sc.shepherd-button {
  border-radius: 8px;
  box-shadow: none;
  font-family: 'MoriGothic-Bold';
  text-transform: capitalize;
}

ion-button,
.sc.shepherd-button {
  transition: transform 0.1s ease-in-out;
}
ion-button:active,
.sc.shepherd-button:active {
  transform: scale(0.98);
}

// icon only
ion-button.button-has-icon-only {
  width: 40px;
  --padding-start: 10px;
  --padding-end: 10px;
  --padding-top: 10px;
  --padding-bottom: 10px;

  ion-icon {
    font-size: 20px;
  }
}

ion-button.button-has-icon-only.button-large {
  width: 48px;
  --padding-start: 12px;
  --padding-end: 12px;
  --padding-top: 12px;
  --padding-bottom: 12px;

  ion-icon {
    font-size: 24px;
  }
}

ion-button.button-has-icon-only.button-small {
  width: 32px;
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 8px;
  --padding-bottom: 8px;

  ion-icon {
    font-size: 16px;
  }
}

// primary
ion-button.primary {
  --color: var(--sc-color-white-100-hex);
  --background: var(--sc-color-primary-110-rgba);
  --border-width: 2px;
  --border-style: solid;
  --border-color: var(--sc-color-primary-110-rgba);
  --transition: all 0.2s ease-in-out;
  --background-hover: var(--sc-color-primary-100-rgba);
  --background-activated: var(--sc-color-primary-100-rgba);
  --background-focused: var(--sc-color-primary-100-rgba);

  ion-icon {
    color: var(--sc-color-white-100-hex);
    transition: all 0.2s ease-in-out;
  }
}

ion-button.primary:hover {
  --border-color: var(--sc-color-primary-100-rgba);
}

ion-button.primary[disabled],
ion-button.primary.disabled {
  opacity: 100%;
  --color: var(--sc-color-black-50-hex);
  --background: var(--sc-color-cool-grey-100-rgba);
  --border-color: var(--sc-color-cool-grey-100-rgba);
  --background-hover: transparent;
  &:hover {
    --border-color: var(--sc-color-primary-100-rgba);
  }

  ion-icon {
    color: var(--sc-color-cool-grey-50-rgba);
  }
}

button.sc.primary.shepherd-button {
  color: var(--sc-color-white-100-hex);
  background: var(--sc-color-primary-110-rgba);
  border-width: 2px;
  border-style: solid;
  border-color: var(--sc-color-primary-110-rgba);
  transition: all 0.2s ease-in-out;
}

button.sc.primary.shepherd-button:hover {
  background: var(--sc-color-primary-100-rgba);
  border-color: var(--sc-color-primary-100-rgba);
}

button.sc.primary.shepherd-button:active {
  background: var(--sc-color-primary-100-rgba);
  border-color: var(--sc-color-primary-110-rgba);
}

button.sc.primary.shepherd-button[disabled] {
  opacity: 100%;
  color: var(--sc-color-cool-grey-50-rgba);
  background: var(--sc-color-cool-grey-100-rgba);
  border-color: var(--sc-color-cool-grey-100-rgba);
}

:root {
  .dark-theme {
    ion-button.primary.disabled,
    ion-button.primary[disabled] {
      --color: var(--sc-color-cool-grey-100-hex);
      --background: var(--sc-color-secondary-dark-mode-bg-hex);
      --border-color: transparent;
      &:hover {
        --border-color: var(--sc-color-primary-100-rgba);
      }

      ion-icon {
        color: var(--sc-color-black-25-rgba);
      }
    }
  }
}

// secondary
ion-button.secondary {
  --color: var(--sc-color-black-75-rgba);
  --background: var(--sc-color-secondary-light-bg-rgba);
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--sc-color-secondary-light-bg-rgba);
  --transition: all 0.2s ease-in-out;
  --background-hover: var(--sc-color-cool-grey-25-rgba);
  --background-activated: var(--sc-color-secondary-light-bg-rgba);
  --background-focused: var(--sc-color-secondary-light-bg-rgba);

  ion-icon {
    color: var(--sc-color-black-75-rgba);
    transition: all 0.2s ease-in-out;
  }
}

ion-button.secondary:hover {
  --border-color: var(--sc-color-cool-grey-25-rgba);
}

ion-button.secondary:active {
  --border-color: var(--sc-color-black-75-rgba);
}

ion-button.secondary[disabled] {
  opacity: 100%;
  --color: var(--sc-color-cool-grey-50-rgba);
  --background: var(--sc-color-cool-grey-100-rgba);
  --border-color: var(--sc-color-cool-grey-100-rgba);

  ion-icon {
    color: var(--sc-color-cool-grey-50-rgba);
  }
}

:root {
  .dark-theme {
    ion-button.secondary {
      --color: var(--sc-color-white-100-rgba);
      --background: var(--sc-color-white-10-rgba);
      --border-color: transparent;

      ion-icon {
        color: var(--sc-color-white-100-rgba);
      }
    }

    ion-button.secondary:hover {
      --background: var(--sc-color-white-25-rgba);
      --background-hover: var(--sc-color-white-25-rgba);
      --border-color: transparent;
      --background-activated: var(--sc-color-white-25-rgba);
      --background-focused: var(--sc-color-white-25-rgba);
    }

    ion-button.secondary:active {
      --border-color: var(--sc-color-white-100-rgba);
    }

    ion-button.secondary[disabled] {
      opacity: 100%;
      --color: var(--sc-color-black-25-rgba);
      --background: var(--sc-color-secondary-dark-bg-rgba);
      --border-color: transparent;

      ion-icon {
        color: var(--sc-color-black-25-rgba);
      }
    }
  }
}

// grey
ion-button.grey {
  --color: var(--sc-color-black-75-rgba);
  --background: var(--sc-color-main-light-mode-bg-hex);
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--sc-color-main-light-mode-bg-hex);
  --transition: all 0.2s ease-in-out;
  --background-hover: var(--sc-color-cool-grey-75-rgba);
  --background-activated: var(--sc-color-cool-grey-75-rgba);
  --background-focused: var(--sc-color-cool-grey-75-rgba);

  ion-icon {
    color: var(--sc-color-black-75-rgba);
    transition: all 0.2s ease-in-out;
  }
}

ion-button.grey:hover {
  --border-color: var(--sc-color-cool-grey-75-rgba);
}

ion-button.grey:active {
  --border-color: var(--sc-color-black-75-rgba);
}

ion-button.grey[disabled] {
  opacity: 100%;
  --color: var(--sc-color-cool-grey-50-rgba);
  --background: var(--sc-color-cool-grey-100-rgba);
  --border-color: var(--sc-color-cool-grey-100-rgba);

  ion-icon {
    color: var(--sc-color-cool-grey-50-rgba);
  }
}

button.sc.grey.shepherd-button {
  color: var(--sc-color-black-75-rgba);
  background: var(--sc-color-main-light-mode-bg-hex);
  border-width: 1px;
  border-style: solid;
  border-color: var(--sc-color-main-light-mode-bg-hex);
  transition: all 0.2s ease-in-out;
}

button.sc.grey.shepherd-button:hover {
  background: var(--sc-color-cool-grey-75-rgba);
  border-color: var(--sc-color-cool-grey-75-rgba);
}

button.sc.grey.shepherd-button:active {
  background: var(--sc-color-cool-grey-75-rgba);
  border-color: var(--sc-color-black-75-rgba);
}

button.sc.grey.shepherd-button[disabled] {
  opacity: 100%;
  color: var(--sc-color-cool-grey-50-rgba);
  background: var(--sc-color-cool-grey-100-rgba);
  border-color: var(--sc-color-cool-grey-100-rgba);

  ion-icon {
    color: var(--sc-color-cool-grey-50-rgba);
  }
}
:root {
  .dark-theme {
    ion-button.grey {
      --color: var(--sc-color-white-100-rgba);
      --background: var(--sc-color-secondary-dark-mode-bg-hex);
      --border-color: var(--sc-color-secondary-dark-mode-bg-hex);
      ion-icon {
        color: var(--sc-color-white-100-rgba);
      }
    }
    ion-button.grey[disabled] {
      --color: var(--sc-color-white-50-rgba);

      ion-icon {
        color: var(--sc-color-white-50-rgba);
      }
    }
  }
}

// Descript
ion-button.descript {
  --color: var(--sc-color-descript-rgba);
  --background: var(--sc-color-white-100-rgba);
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--sc-color-descript-rgba);
  --transition: all 0.2s ease-in-out;
  --background-hover: var(--sc-color-cool-grey-25-rgba);
  --background-activated: var(--sc-color-grey-75-rgba);
  --background-focused: var(--sc-color-cool-grey-75-rgba);
  --padding-start: 14px;
  --padding-end: 10px;

  ion-icon {
    color: var(--sc-color-descript-rgba);
    transition: all 0.2s ease-in-out;
  }

  span {
    padding-top: 2px;
  }
}

ion-button.descript:hover {
  --border-color: rgba(var(--sc-color-descript-rgb), 0.5);
}

ion-button.descript:active {
  --border-color: var(--sc-color-descript-rgba);
}

ion-button.descript[disabled] {
  opacity: 100%;
  --color: var(--sc-color-cool-grey-50-rgba);
  --background: var(--sc-color-cool-grey-100-rgba);
  --border-color: var(--sc-color-cool-grey-100-rgba);

  ion-icon {
    color: var(--sc-color-cool-grey-50-rgba);
  }
}

:root {
  .dark-theme {
    ion-button.descript {
      // --color: var(--sc-color-white-100-rgba);
      // --background: var(--sc-color-white-10-rgba);
      --border-color: transparent;
      --background: var(--sc-color-descript-rgba);
      --color: var(--sc-color-white-100-rgba);

      ion-icon {
        color: var(--sc-color-white-100-rgba);
      }
    }

    // ion-button.descript:hover {
    //   --background: var(--sc-color-white-25-rgba);
    //   --background-hover: var(--sc-color-white-25-rgba);
    //   --border-color: transparent;
    //   --background-activated: var(--sc-color-white-25-rgba);
    //   --background-focused: var(--sc-color-white-25-rgba);
    // }

    // ion-button.descript:active {
    //   --border-color: var(--sc-color-descript-rgba);
    // }

    // ion-button.descript[disabled] {
    //   opacity: 100%;
    //   --color: var(--sc-color-black-25-rgba);
    //   --background: var(--sc-color-secondary-dark-bg-rgba);
    //   --border-color: transparent;

    //   ion-icon {
    //     color: var(--sc-color-black-25-rgba);
    //   }
    // }
  }
}

// text
ion-button.text {
  --color: var(--sc-color-primary-110-rgba);
  --background: transparent;
  --transition: all 0.2s ease-in-out;
  --background-hover: transparent;

  ion-icon {
    color: var(--sc-color-primary-110-rgba);
    transition: all 0.2s ease-in-out;
  }
}

ion-button.text:hover {
  --color: var(--sc-color-primary-100-rgba);

  ion-icon {
    color: var(--sc-color-primary-100-rgba);
  }
}

ion-button.text:active {
  --background: var(--sc-color-primary-10-rgba);
}

ion-button.text[disabled] {
  opacity: 100%;
  --color: var(--sc-color-cool-grey-100-rgba);
  --background: transparent;

  ion-icon {
    color: var(--sc-color-cool-grey-100-rgba);
  }
}

button.sc.text.shepherd-button {
  color: var(--sc-color-primary-110-rgba);
  background: transparent;
  transition: all 0.2s ease-in-out;
}

button.sc.text.shepherd-button:hover {
  color: var(--sc-color-primary-100-rgba);
}

button.sc.text.shepherd-button:active {
  background: var(--sc-color-primary-10-rgba);
}

button.sc.text.shepherd-button[disabled] {
  opacity: 100%;
  color: var(--sc-color-cool-grey-100-rgba);
  background: transparent;
}

:root {
  .dark-theme {
    ion-button.text {
      --color: var(--sc-color-primary-100-rgba);

      ion-icon {
        color: var(--sc-color-primary-100-rgba);
      }
    }

    ion-button.text:hover {
      --color: var(--sc-color-primary-110-rgba);

      ion-icon {
        color: var(--sc-color-primary-110-rgba);
      }
    }

    ion-button.text:active {
      --color: var(--sc-color-primary-100-rgba);

      ion-icon {
        color: var(--sc-color-primary-100-rgba);
      }
    }

    ion-button.text[disabled] {
      --color: var(--sc-color-black-25-rgba);

      ion-icon {
        color: var(--sc-color-black-25-rgba);
      }
    }

    button.sc.text.shepherd-button {
      --color: var(--sc-color-primary-100-rgba);

      ion-icon {
        color: var(--sc-color-primary-100-rgba);
      }
    }

    button.sc.text.shepherd-button:hover {
      --color: var(--sc-color-primary-110-rgba);

      ion-icon {
        color: var(--sc-color-primary-110-rgba);
      }
    }

    button.sc.text.shepherd-button:active {
      --color: var(--sc-color-primary-100-rgba);

      ion-icon {
        color: var(--sc-color-primary-100-rgba);
      }
    }

    button.sc.text.shepherd-button[disabled] {
      --color: var(--sc-color-black-25-rgba);

      ion-icon {
        color: var(--sc-color-black-25-rgba);
      }
    }
  }
}

// solid
ion-button.button-solid.primary {
  --background-hover-opacity: 1;
}

// line
ion-button.line {
  --color: var(--sc-color-primary-110-rgba);
  --background: transparent;
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--sc-color-primary-110-rgba);
  --transition: all 0.2s ease-in-out;
  --background-hover: var(--sc-color-primary-10-rgba);

  ion-icon {
    color: var(--sc-color-primary-110-rgba);
    transition: all 0.2s ease-in-out;
  }
}

ion-button.line:hover {
  --color: var(--sc-color-primary-100-rgba);
  --border-color: var(--sc-color-primary-100-rgba);

  ion-icon {
    color: var(--sc-color-primary-100-rgba);
  }
}

ion-button.line:active {
  --color: var(--sc-color-primary-100-rgba);
  --background: var(--sc-color-primary-10-rgba);
  --border-color: transparent;

  ion-icon {
    color: var(--sc-color-primary-100-rgba);
  }
}

ion-button.line[disabled],
ion-button.line.disabled {
  opacity: 100%;
  --color: var(--sc-color-cool-grey-100-rgba);
  --background: transparent;
  --border-color: var(--sc-color-cool-grey-100-rgba);

  ion-icon {
    color: var(--sc-color-cool-grey-100-rgba);
  }
}

:root {
  .dark-theme {
    ion-button.line {
      --color: var(--sc-color-primary-100-rgba);
      --border-color: var(--sc-color-primary-100-rgba);

      ion-icon {
        color: var(--sc-color-primary-100-rgba);
      }
    }

    ion-button.line:hover {
      --color: var(--sc-color-primary-110-rgba);
      --border-color: var(--sc-color-primary-110-rgba);

      ion-icon {
        color: var(--sc-color-primary-110-rgba);
      }
    }

    ion-button.line:active {
      --color: var(--sc-color-primary-110-rgba);
      --border-color: var(--sc-color-primary-110-rgba);

      ion-icon {
        color: var(--sc-color-primary-110-rgba);
      }
    }

    ion-button.line[disabled] {
      --color: var(--sc-color-black-25-rgba);
      --border-color: var(--sc-color-black-25-rgba);

      ion-icon {
        color: var(--sc-color-black-25-rgba);
      }
    }
  }
}

// sizes
ion-button {
  height: 40px;
  font-size: 14px;
}

ion-button.button-large {
  height: 48px;
  font-size: 16px;
}

ion-button.button-small {
  height: 32px;
  font-size: 12px;
}

// warning
ion-button.warning {
  --color: var(--sc-color-sc-color-black-75-rgba);
  --background: var(--sc-color-state-warning-bg-rgba);
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--sc-color-state-warning-bg-rgba);
  --transition: all 0.2s ease-in-out;
  --background-hover: var(--sc-color-state-warning-rgba);
  --background-activated: var(--sc-color-state-warning-rgba);
  --background-focused: var(--sc-color-state-warning-rgba);

  ion-icon {
    color: var(--sc-color-state-warning-rgba);
    transition: all 0.2s ease-in-out;
  }
}

ion-button.warning:hover {
  --border-color: var(--sc-color-state-warning-rgba);
}

ion-button.warning:active {
  --border-color: var(--sc-color-state-warning-rgba);
}

ion-button.warning[disabled] {
  opacity: 100%;
  --color: var(--sc-color-state-warning-rgba);
  --background: var(--sc-color-state-warning-rgba);
  --border-color: var(--sc-color-state-warning-rgba);

  ion-icon {
    color: var(--sc-color-state-warning-rgba);
  }
}

button.sc.warning.shepherd-button {
  color: var(--sc-color-state-warning-rgba);
  background: var(--sc-color-state-warning-bg-hex);
  border-width: 1px;
  border-style: solid;
  border-color: var(--sc-color-state-warning-bg-hex);
  transition: all 0.2s ease-in-out;
}

button.sc.warning.shepherd-button:hover {
  background: var(--sc-color-state-warning-rgba);
  border-color: var(--sc-color-state-warning-rgba);
}

button.sc.warning.shepherd-button:active {
  background: var(--sc-color-state-warning-rgba);
  border-color: var(--sc-color-state-warning-rgba);
}

button.sc.warning.shepherd-button[disabled] {
  opacity: 100%;
  color: var(--sc-color-state-warning-rgba);
  background: var(--sc-color-state-warning-rgba);
  border-color: var(--sc-color-state-warning-rgba);

  ion-icon {
    color: var(--sc-color-state-warning-rgba);
  }
}

// danger
ion-button.danger {
  --color: var(--sc-color-white-100-rgba);
  --background: rgba(var(--sc-color-state-error-rgb), 0.9);
  --background-hover: rgba(var(--sc-color-state-error-rgb), 1);
}

ion-button.danger.line {
  --color: var(--sc-color-state-error-rgba);
  --background: transparent;
  --background-hover: rgba(var(--sc-color-state-error-rgb), 0.5);
  --border-color: var(--sc-color-state-error-rgba);
}
