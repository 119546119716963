@import './colors.scss';

// GLOBAL NG-SECELT STYLES
.ng-select,
.ng-dropdown-panel {
  --height: 46px;
  --background-color: var(--sc-color-white-100-hex);
  --selected-background-color: 150, 150, 150;
  --border-color: var(--sc-color-cool-grey-75-rgba);
  --color: var(--sc-color-black-100-rgba);
}
.dark-theme .ng-select,
.dark-theme .ng-dropdown-panel {
  --background-color: var(--sc-color-main-dark-mode-bg-hex);
  --selected-background-color: 255, 255, 255;
  --border-color: var(--sc-color-light-dark-mode-bg-hex);
  --color: var(--sc-color-white-100-hex);
}

.ng-select {
  min-width: 0px !important;
  width: -moz-available;
  width: -webkit-fill-available;
  width: stretch;
  height: var(--height) !important;
  background-color: var(--background-color) !important;
  color: var(--color) !important;
  border-radius: 8px;

  &.black-25 {
    .ng-select-container {
      --color: var(--sc-color-black-25-rgba);
    }
  }

  &.border-4 {
    .ng-select-container {
      border-radius: 4px;
    }
  }

  .ng-select-container {
    background-color: var(--background-color) !important;
    color: var(--color) !important;
    border-radius: 8px;
    min-height: 0px !important;
    height: 100% !important;
    border: 1px solid var(--border-color) !important;

    .ng-value-container {
      padding: 0 8px 0 8px !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;

      .ng-placeholder {
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        white-space: nowrap !important;
        position: static !important;
        padding: 0 !important;
        width: 0 !important;
        flex: 1 !important;
      }

      .ng-value {
        flex: 1 !important;
      }

      .ng-input {
        position: absolute !important;
        padding: 0 50px 0 0 !important;
        top: unset !important;
        left: 7px !important;

        input {
          color: unset !important;
        }
      }
    }
    .ng-clear-wrapper {
      .ng-clear {
        font-size: unset !important;
      }
    }
  }

  &.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    display: none !important;
  }
  &[ng-reflect-readonly] .ng-arrow-wrapper {
    display: none !important;
  }
  &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border: 2px solid var(--sc-color-primary-100-rgba) !important;
    box-shadow: none !important;
  }
  .ng-dropdown-panel {
    --height: inherit !important;
    --background-color: inherit !important;
    --selected-background-color: inherit !important;
    --border-color: inherit !important;
    --color: inherit !important;
  }
}

.ng-select.small-select {
  --height: 28px !important;
  margin: 4px 0;
  font-size: 12px;
  border-radius: 4px;
  .ng-select-container {
    border-radius: 4px;
  }
}

.ng-dropdown-panel {
  background-color: var(--background-color) !important;
  border-color: var(--border-color) !important;

  .ng-dropdown-panel-items .ng-option {
    background-color: var(--background-color) !important;
    height: calc(var(--height) - 2px) !important;
    display: flex !important;
    align-items: center !important;
    padding: 0 8px 0 8px !important;

    & > span {
      flex: 1 !important;
    }

    &.ng-option-selected {
      background-color: rgba(var(--selected-background-color), 0.1) !important;
    }
    &.ng-option-marked {
      background-color: rgba(var(--selected-background-color), 0.2) !important;
    }
    &:not(.ng-option-disabled) {
      color: var(--color) !important;
    }

    .ng-option-label {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.dark-theme .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  color: var(--sc-color-black-25-rgba);
}
// GLOBAL NG-SECELT STYLES-END

// GLOBAL AIS-REFINEMNETS STYLES
.ais-CurrentRefinements-list {
  margin: 5px;
}
.ais-CurrentRefinements-item,
.ais-CurrentRefinements-category,
.ais-CurrentRefinements-categoryLabel {
  overflow: hidden;
}
.ais-CurrentRefinements-category {
  align-items: center;
}
.ais-CurrentRefinements-item {
  padding-top: 3px;
}
.dark-theme {
  .ais-CurrentRefinements-item {
    background-color: var(--sc-color-secondary-dark-mode-bg-hex);
    color: var(--sc-color-white-100-hex);
  }
  .ais-SearchBox .ais-SearchBox-input {
    color: var(--sc-color-white-100-rgba);
  }
}
// GLOBAL AIS-REFINEMNETS STYLES-END

// IONIC
ion-item.menu-large,
ion-item.menu-small {
  overflow: visible;
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-end: 0;
}

ion-item ion-label + ng-select {
  margin-top: 10px;
}

ion-item.warning.item-interactive-disabled:not(.item-multiple-inputs) {
  --background: var(--sc-color-state-warning-rgba);
  color: var(--sc-color-black-100-rgba);
  ion-label {
    opacity: 1;
  }
}

.dark-theme {
  ion-popover {
    --background: var(--sc-color-light-dark-mode-bg-hex);
    --ion-item-background: var(--sc-color-light-dark-mode-bg-hex);
  }
}
ion-popover .popover-viewport,
ion-popover::part(content) {
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  width: 500px;
}
ion-popover.participant-equipment-popover {
  --width: 300px;
  --background: transparent;
  animation: backdrop-blur-in 500ms forwards;
}
ion-popover.participant-equipment-popover .popover-viewport,
ion-popover.participant-equipment-popover::part(content) {
  border-radius: 5px;
}

ion-popover.date-popover {
  --width: 284px;
}

.dark-theme {
  ion-datetime {
    --background: var(--sc-color-main-dark-mode-bg-hex);
    --ion-color-step-500: var(--sc-color-white-75-rgba);
    --ion-color-step-650: var(--sc-color-white-80-rgba);
    --color: var(--sc-color-white-75-rgba);
    border: 2px solid var(--sc-color-secondary-dark-mode-bg-hex);
    border-radius: 9px;
  }
}
// IONIC END

// MENUS
.recordings-list-menu {
  --width: 560px;
  --max-width: 97vw;
  --height: 500px;
  --background: var(--ion-color-medium);
}

.chat-menu {
  --width: 400px;
  --max-width: 97vw;
  --background: var(--ion-color-medium);
}

.navigation-menu,
.api-menu {
  --width: auto;
}

.help-menu {
  --width: 305px;
}

.notifications-menu {
  --width: 500px;
  --max-width: 94vw;
}

.organizations-menu {
  --width: 500px;
  --max-width: 94vw;
  --max-height: 80vh;
}

.plans-menu {
  --width: 300px;
  --max-width: 97vw;
}

.new-session-menu {
  --width: 370px;
  --max-width: 97vw;
}

.name-menu,
.equipment-menu,
.info-menu,
.open-invitation-link-menu {
  --width: 370px;
  --max-width: 97vw;
}

.recording-menu-component {
  --width: 330px;
}

.name-menu {
  --width: 480px;
}

.guests-menu {
  --width: 470px;
  --max-width: 97vw;
}

.add-sidebar-menu {
  --width: auto;
}

.move-session-popover {
  --width: 480px;
  --max-width: 97vw;
}

.show-select-popover {
  --offset-y: 4px;
}

@media only screen and (max-width: 767px) {
  .guests-menu {
    --width: 370px;
  }
}
// MENUS END
