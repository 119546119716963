.modal-default {
  --border-radius: 8px;
  backdrop-filter: blur(5px);
  background: #00000020;
}

.action-confirm-modal {
  --height: 250px;
  --width: 500px;
}

.advanced-show-settings-modal {
  --height: 600px;
  --width: 900px;
}

.descript-link-modal {
  --height: auto;
  --width: 800px;
  --max-height: 100vh;
  --overflow: visible;
}

.workflow-action-modal {
  --height: 240px;
}

.connect-email-modal,
.edit-email-modal {
  --height: 500px;
}

.test-equipment-result-modal {
  --height: 75%;
  --width: 700px;
}

.beta-feedback-modal {
  --height: 60vh;
}

.new-webhook-modal,
.edit-webhook-modal {
  --width: 65vw;
  --height: 80vh;
}

.cancel-subscription-modal {
  --height: auto;
  --width: 850px;
}

.cancel-subscription-confirm-modal {
  --height: 400px;
}

.reactivate-subscription-modal {
  --height: 400px;
}

.re-render-squadshot-modal {
  --height: 675px;
  --width: 1200px;
}

.notifications-modal {
  --height: 700px;
}

.move-session-modal {
  --height: 440px;
}

.still-here-modal,
.still-here-modal {
  --height: 60vh;
  --width: 50vw;
}

.verify-auth-modal {
  --height: 500px;
}

.edit-password-modal {
  --height: 500px;
}

.confirm-subscription-modal {
  --height: 672px;
  --width: 1280px;
  --max-width: 100vw;
}

.dropbox-modal,
.update-payment-info-modal {
  --height: 550px;
}

.password-reset-modal {
  --height: 330px;
  --border-radius: 10px;
  --width: 480px;
}

.team-invite-modal {
  --height: 400px;
  --width: 450px;
  --ion-backdrop-opacity: 1;
  --ion-backdrop-color: var(--sc-color-light-light-mode-bg-hex);
  --ion-background-color: var(--sc-color-white-100-hex);
}
.dark-theme .team-invite-modal {
  --ion-backdrop-color: var(--sc-color-main-dark-mode-bg-hex);
  --ion-background-color: var(--sc-color-secondary-dark-mode-bg-hex);
}

@media only screen and (max-width: 1024px) {
  .confirm-subscription-modal {
    --height: 740px;
  }
}

@media only screen and (max-width: 767px) {
  .modal-default {
    --mobile-header-height: 60px;
    --width: 100vw;
    --height: calc(var(--app-height) - var(--mobile-header-height));
    padding-top: var(--mobile-header-height);
    --overflow: auto;

    &::after {
      content: '✕';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: calc(var(--mobile-header-height) - 1px);
      font-size: 24px;
      font-weight: 700;
      display: flex;
      justify-content: flex-end;
      line-height: var(--mobile-header-height);
      padding-right: 20px;
      cursor: pointer;
      pointer-events: none;
      z-index: 2;
      background-color: var(--background);
      color: var(--sc-color-black-100-hex);
      border-bottom: 1px solid var(--sc-color-cool-grey-100-hex);
    }
    &.team-invite-modal::after {
      content: '';
    }

    & > * {
      height: auto !important;
    }
  }

  .dark-theme .modal-default::after {
    color: var(--sc-color-white-100-hex);
  }

  .confirm-subscription-modal {
    --height: 100vh;
    --width: 100vw;
    --max-width: 100vw;
    padding-top: 0;

    &::after {
      display: none;
    }
  }
}
