@font-face {
  font-family: 'MoriGothic-Regular';
  src: url('./../assets/fonts/MoriGothic-Regular.woff2') format('woff2'),
    url('./../assets/fonts/MoriGothic-Regular.woff') format('woff');
}

@font-face {
  font-family: 'MoriGothic-SemiBold';
  src: url('./../assets/fonts/MoriGothic-SemiBold.woff2') format('woff2'),
    url('./../assets/fonts/MoriGothic-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'MoriGothic-Bold';
  src: url('./../assets/fonts/MoriGothic-Bold.woff2') format('woff2'),
    url('./../assets/fonts/MoriGothic-Bold.woff') format('woff');
}

:root {
  --ion-font-family: 'MoriGothic-Regular';
}

.regular {
  font-family: 'MoriGothic-Regular' !important;
}

.medium {
  font-family: 'MoriGothic-SemiBold' !important;
}

.bold {
  font-family: 'MoriGothic-Bold' !important;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}

.sc-ion-label-md-s p {
  color: unset;
}

.action-large {
  font-size: 16px;
  font-family: 'MoriGothic-SemiBold';
}

.action-medium {
  font-size: 14px;
  font-family: 'MoriGothic-SemiBold';
}

.action-small {
  font-size: 12px;
  font-family: 'MoriGothic-SemiBold';
}

.micro-bold {
  font-size: 12px;
  font-family: 'MoriGothic-SemiBold';
  line-height: 125%;
}

.micro-regular {
  font-size: 12px;
  font-family: 'MoriGothic-Regular';
  line-height: 125%;
}

.micro-regular-caps {
  font-size: 12px;
  font-family: 'MoriGothic-Regular';
  line-height: 125%;
  text-transform: uppercase;
}

.micro-bold-caps {
  font-size: 12px;
  font-family: 'MoriGothic-Bold';
  line-height: 125%;
  text-transform: uppercase;
}

.body-regular {
  font-size: 14px;
  font-family: 'MoriGothic-Regular';
  line-height: 150%;
}

.body-bold {
  font-size: 14px;
  font-family: 'MoriGothic-Bold';
  line-height: 150%;
}

.body-small {
  font-size: 12px;
  font-family: 'MoriGothic-Regular';
  line-height: 130%;
}

.body-small-bold {
  font-size: 12px;
  font-family: 'MoriGothic-Bold';
  line-height: 130%;
}

.pica-bold {
  font-size: 10px;
  font-family: 'MoriGothic-Bold';
}

.pica-regular {
  font-size: 10px;
  font-family: 'MoriGothic-Regular';
}

.pica-regular-caps {
  font-size: 10px;
  font-family: 'MoriGothic-Regular';
  text-transform: uppercase;
}

.pica-bold-caps {
  font-size: 10px;
  font-family: 'MoriGothic-Bold';
  text-transform: uppercase;
}

/* Text BG Hightlight */
.primary-highlight-top {
  background: linear-gradient(
    0deg,
    rgba(64, 183, 186, 0) 0%,
    rgba(64, 183, 186, 0) 40%,
    rgba(var(--ion-color-primary-rgb), 0.7) 41%,
    rgba(var(--ion-color-primary-rgb), 0.7) 100%
  );
  padding-right: 10px;
  margin-right: -10px;
}

.primary-highlight-bottom {
  background: linear-gradient(
    0deg,
    rgba(var(--ion-color-primary-rgb), 0.7) 0%,
    rgba(var(--ion-color-primary-rgb), 0.7) 40%,
    rgba(64, 183, 186, 0) 41%,
    rgba(64, 183, 186, 0) 100%
  );
  padding-left: 10px;
  margin-left: -10px;
}

.secondary-highlight-top {
  background: linear-gradient(
    0deg,
    rgba(224, 108, 156, 0) 0%,
    rgba(224, 108, 156, 0) 40%,
    rgba(var(--ion-color-secondary-rgb), 0.7) 41%,
    rgba(var(--ion-color-secondary-rgb), 0.7) 100%
  );
  padding-right: 10px;
  margin-right: -10px;
}

.secondary-highlight-bottom {
  background: linear-gradient(
    0deg,
    rgba(var(--ion-color-secondary-rgb), 0.7) 0%,
    rgba(var(--ion-color-secondary-rgb), 0.7) 40%,
    rgba(224, 108, 156, 0) 41%,
    rgba(224, 108, 156, 0) 100%
  );
  padding-left: 10px;
  margin-left: -10px;
}

.tertiary-highlight-top {
  background: linear-gradient(
    0deg,
    rgba(249, 185, 43, 0) 0%,
    rgba(249, 185, 43, 0) 40%,
    rgba(var(--ion-color-tertiary-rgb), 0.7) 41%,
    rgba(var(--ion-color-tertiary-rgb), 0.7) 100%
  );
  padding-right: 10px;
  margin-right: -10px;
}

.tertiary-highlight-line {
  padding-right: 10px;
  margin-right: -10px;
}

.tertiary-highlight-bottom {
  background: linear-gradient(
    0deg,
    rgba(var(--ion-color-tertiary-rgb), 0.7) 0%,
    rgba(var(--ion-color-tertiary-rgb), 0.7) 40%,
    rgba(249, 185, 43, 0) 41%,
    rgba(249, 185, 43, 0) 100%
  );
  padding-left: 10px;
  margin-left: -10px;
}

.strike {
  text-decoration: line-through;
}

::selection {
  color: var(--ion-color-black);
  background: var(--ion-color-primary);
}
