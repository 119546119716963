@import './colors.scss';

ion-input {
  caret-color: var(--sc-color-black-75-rgba);
}

ion-item.input-large,
ion-item.input-small {
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-end: 0px;
}

ion-item.input-large ion-input,
ion-item.input-small ion-input {
  --highlight-color-focused: transparent;
  --highlight-color-valid: transparent;
  caret-color: var(--sc-color-primary-100-rgba);
  font-size: 14px !important;
  transition: all 0.3s ease-in-out;

  ion-icon {
    color: var(--sc-color-cool-black-25-rgba);
    font-size: 16px;
  }

  ion-icon[slot='start'] {
    margin-left: 12px;
  }
}

ion-item.input-large ion-label,
ion-item.input-small ion-label {
  --color: var(--sc-color-black-75-rgba) !important;
}

ion-item.input-large ion-input {
  border: 1px solid var(--sc-color-cool-grey-75-rgba);
  border-radius: 8px;
  margin-top: 10px;
  --padding-start: 12px !important;
  --padding-end: 12px !important;
  --padding-top: 14px !important;
  --padding-bottom: 14px !important;
  --height: 48px;
  --color: var(--sc-color-black-100-rgba);
}

ion-item.input-small ion-input {
  border: 1px solid var(--sc-color-cool-grey-75-rgba);
  border-radius: 8px;
  margin-top: 10px;
  --padding-start: 12px !important;
  --padding-end: 12px !important;
  --height: 32px;
  --color: var(--sc-color-black-100-rgba);
}

ion-item.input-large ion-input:active,
ion-item.input-large ion-input.has-focus,
ion-item.input-small ion-input:active,
ion-item.input-small ion-input.has-focus {
  border: 2px solid var(--sc-color-primary-100-rgba);
}

ion-item.input-small ion-input.ng-invalid:not(.ng-untouched):not(.has-focus),
ion-item.input-large ion-input.ng-invalid:not(.ng-untouched):not(.has-focus) {
  border: 1px solid var(--sc-color-state-error-rgba);
  animation: bounce 0.5s 1;
}

.validation-error {
  color: var(--sc-color-state-error-rgba);
  p {
    font-size: 12px;
    font-weight: bold;
    padding-bottom: 0;
  }
}

ion-input .validation-error {
  position: absolute;
  right: 14px;
}

.dark-theme {
  ion-item.input-large ion-input ion-icon,
  ion-item.input-small ion-input ion-icon {
    color: var(--sc-color-cool-white-25-rgba);
  }

  ion-item.input-large ion-label,
  ion-item.input-small ion-label {
    --color: var(--sc-color-white-75-rgba) !important;
  }

  ion-item.input-large ion-input,
  ion-item.input-small ion-input {
    border: 1px solid var(--sc-color-white-25-rgba);
    --color: var(--sc-color-white-100-rgba);
  }

  ion-item.input-large ion-input:active,
  ion-item.input-large ion-input.has-focus,
  ion-item.input-small ion-input:active,
  ion-item.input-small ion-input.has-focus {
    border: 2px solid var(--sc-color-primary-110-rgba);
  }

  ion-checkbox {
    --background: var(--sc-color-secondary-dark-mode-bg-hex);
  }
}
