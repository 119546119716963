:root {
  // Temporary Descript colors
  --descript-color-blue-500-hex: #0045e5;
  --descript-color-light-green-500-hex: #009b00;
  --descript-color-grey-900-hex: #1a1a1a;
  --descript-color-grey-800-hex: #333333; //TODO: Not sure if this is accurate, co-pilot said so. Make sure to check with design when figma is shared :D
  --descript-color-grey-400-hex: #858585;
  --descript-color-grey-200-hex: #cccccc;

  // light mode background
  --sc-color-main-light-mode-bg-hex: #d8dee9;
  --sc-color-main-light-mode-bg-rgb: 216, 222, 233;
  --sc-color-main-light-mode-bg-rgba: rgba(var(--sc-color-main-light-mode-bg-rgb), 1);
  .sc-color-main-light-mode-bg {
    background-color: var(--sc-color-main-light-mode-bg-rgba);
    --background: var(--sc-color-main-light-mode-bg-rgba);
  }

  --sc-color-secondary-light-mode-bg-hex: #eceff3;
  --sc-color-secondary-light-mode-bg-rgb: 236, 239, 243;
  --sc-color-secondary-light-mode-bg-rgba: rgba(var(--sc-color-secondary-light-mode-bg-rgb), 1);
  .sc-color-secondary-light-mode-bg {
    background-color: var(--sc-color-secondary-light-mode-bg-rgba);
    --background: var(--sc-color-secondary-light-mode-bg-rgba);
  }

  --sc-color-light-light-mode-bg-hex: #f7f9fc;
  --sc-color-light-light-mode-bg-rgb: 247, 249, 252;
  --sc-color-light-light-mode-bg-rgba: rgba(var(--sc-color-light-light-mode-bg-rgb), 1);
  .sc-color-light-light-mode-bg {
    background-color: var(--sc-color-light-light-mode-bg-rgba);
    --background: var(--sc-color-light-light-mode-bg-rgba);
  }

  // dark mode background
  --sc-color-main-dark-mode-bg-hex: #0a122f;
  --sc-color-main-dark-mode-bg-rgb: 10, 18, 47;
  --sc-color-main-dark-mode-bg-rgba: rgba(var(--sc-color-main-dark-mode-bg-rgb), 1);
  .sc-color-main-dark-mode-bg {
    background-color: var(--sc-color-main-dark-mode-bg-rgba);
    --background: var(--sc-color-main-dark-mode-bg-rgba);
  }

  --sc-color-secondary-dark-mode-bg-hex: #1f2842;
  --sc-color-secondary-dark-mode-bg-rgb: 31, 40, 66;
  --sc-color-secondary-dark-mode-bg-rgba: rgba(var(--sc-color-secondary-dark-mode-bg-rgb), 1);
  .sc-color-secondary-dark-mode-bg {
    background-color: var(--sc-color-secondary-dark-mode-bg-rgba);
    --background: var(--sc-color-secondary-dark-mode-bg-rgba);
  }

  --sc-color-darker-dark-mode-bg-hex: #040b22;
  --sc-color-darker-dark-mode-bg-rgb: 4, 11, 34;
  --sc-color-darker-dark-mode-bg-rgba: rgba(var(--sc-color-darker-dark-mode-bg-rgb), 1);
  .sc-color-darker-dark-mode-bg {
    background-color: var(--sc-color-darker-dark-mode-bg-rgba);
    --background: var(--sc-color-darker-dark-mode-bg-rgba);
  }

  --sc-color-light-dark-mode-bg-hex: #3b4154;
  --sc-color-light-dark-mode-bg-rgb: 59, 65, 84;
  --sc-color-light-dark-mode-bg-rgba: rgba(var(--sc-color-light-dark-mode-bg-rgb), 1);
  .sc-color-light-dark-mode-bg {
    background-color: var(--sc-color-light-dark-mode-bg-rgba);
    --background: var(--sc-color-light-dark-mode-bg-rgba);
  }

  --sc-color-lighter-dark-mode-bg-hex: #5f6578;
  --sc-color-lighter-dark-mode-bg-rgb: 95, 101, 120;
  --sc-color-lighter-dark-mode-bg-rgba: rgba(var(--sc-color-lighter-dark-mode-bg-rgb), 1);
  .sc-color-lighter-dark-mode-bg {
    background-color: var(--sc-color-lighter-dark-mode-bg-rgba);
    --background: var(--sc-color-lighter-dark-mode-bg-rgba);
  }

  --sc-color-red-dark-mode-bg-hex: #621717;
  --sc-color-red-dark-mode-bg-rgb: 98, 23, 23;
  --sc-color-red-dark-mode-bg-rgba: rgba(var(--sc-color-red-dark-mode-bg-rgb), 1);
  .sc-color-red-dark-mode-bg {
    background-color: var(--sc-color-red-dark-mode-bg-rgba);
    --background: var(--sc-color-red-dark-mode-bg-rgba);
  }

  // primary
  // --sc-color-primary-110-hex: #108d90;
  // --sc-color-primary-110-rgb: 16, 141, 144;
  --sc-color-primary-110-hex: #0f8385;
  --sc-color-primary-110-rgb: 15, 131, 133;

  --sc-color-primary-110-rgba: rgba(var(--sc-color-primary-110-rgb), 1);
  .sc-color-primary-110 {
    color: var(--sc-color-primary-110-rgba);
  }
  .sc-color-primary-110-bg {
    background-color: var(--sc-color-primary-110-rgba);
    --background: var(--sc-color-primary-110-rgba);
  }

  --sc-color-primary-100-hex: #40b7ba;
  --sc-color-primary-100-rgb: 64, 183, 186;
  --sc-color-primary-100-rgba: rgba(var(--sc-color-primary-100-rgb), 1);
  .sc-color-primary-100 {
    color: var(--sc-color-primary-100-rgba);
  }
  .sc-color-primary-100-bg {
    background-color: var(--sc-color-primary-100-rgba);
    --background: var(--sc-color-primary-100-rgba);
  }

  --sc-color-primary-75-hex: #40b7ba;
  --sc-color-primary-75-rgb: 64, 183, 186;
  --sc-color-primary-75-rgba: rgba(var(--sc-color-primary-75-rgb), 0.75);
  .sc-color-primary-75 {
    color: var(--sc-color-primary-75-rgba);
  }
  .sc-color-primary-75-bg {
    background-color: var(--sc-color-primary-75-rgba);
    --background: var(--sc-color-primary-75-rgba);
  }

  --sc-color-primary-50-hex: #40b7ba;
  --sc-color-primary-50-rgb: 64, 183, 186;
  --sc-color-primary-50-rgba: rgba(var(--sc-color-primary-50-rgb), 0.5);
  .sc-color-primary-50 {
    color: var(--sc-color-primary-50-rgba);
  }
  .sc-color-primary-50-bg {
    background-color: var(--sc-color-primary-50-rgba);
    --background: var(--sc-color-primary-50-rgba);
  }

  --sc-color-primary-25-hex: #40b7ba;
  --sc-color-primary-25-rgb: 64, 183, 186;
  --sc-color-primary-25-rgba: rgba(var(--sc-color-primary-25-rgb), 0.25);
  .sc-color-primary-25 {
    color: var(--sc-color-primary-25-rgba);
  }
  .sc-color-primary-25-bg {
    background-color: var(--sc-color-primary-25-rgba);
    --background: var(--sc-color-primary-25-rgba);
  }

  --sc-color-primary-10-hex: #108d90;
  --sc-color-primary-10-rgb: 16, 141, 144;
  --sc-color-primary-10-rgba: rgba(var(--sc-color-primary-10-rgb), 0.1);
  .sc-color-primary-10 {
    color: var(--sc-color-primary-10-rgba);
  }
  .sc-color-primary-10-bg {
    background-color: var(--sc-color-primary-10-rgba);
    --background: var(--sc-color-primary-10-rgba);
  }

  // secondary
  --sc-color-secondary-110-hex: ##d5396d;
  --sc-color-secondary-110-rgb: 215, 100, 138;

  --sc-color-secondary-110-rgba: rgba(var(--sc-color-secondary-110-rgb), 1);
  .sc-color-secondary-110 {
    color: var(--sc-color-secondary-110-rgba);
  }
  .sc-color-secondary-110-bg {
    background-color: var(--sc-color-secondary-110-rgba);
    --background: var(--sc-color-secondary-110-rgba);
  }

  --sc-color-secondary-100-hex: #e06c92;
  --sc-color-secondary-100-rgb: 224, 108, 146;
  --sc-color-secondary-100-rgba: rgba(var(--sc-color-secondary-100-rgb), 1);
  .sc-color-secondary-100 {
    color: var(--sc-color-secondary-100-rgba);
  }
  .sc-color-secondary-100-bg {
    background-color: var(--sc-color-secondary-100-rgba);
    --background: var(--sc-color-secondary-100-rgba);
  }

  --sc-color-secondary-75-hex: #e06c92;
  --sc-color-secondary-75-rgb: 224, 108, 146;
  --sc-color-secondary-75-rgba: rgba(var(--sc-color-secondary-75-rgb), 0.75);
  .sc-color-secondary-75 {
    color: var(--sc-color-secondary-75-rgba);
  }
  .sc-color-secondary-75-bg {
    background-color: var(--sc-color-secondary-75-rgba);
    --background: var(--sc-color-secondary-75-rgba);
  }

  --sc-color-secondary-50-hex: #e06c92;
  --sc-color-secondary-50-rgb: 224, 108, 146;
  --sc-color-secondary-50-rgba: rgba(var(--sc-color-secondary-50-rgb), 0.5);
  .sc-color-secondary-50 {
    color: var(--sc-color-secondary-50-rgba);
  }
  .sc-color-secondary-50-bg {
    background-color: var(--sc-color-secondary-50-rgba);
    --background: var(--sc-color-secondary-50-rgba);
  }

  --sc-color-secondary-25-hex: #e06c92;
  --sc-color-secondary-25-rgb: 224, 108, 146;
  --sc-color-secondary-25-rgba: rgba(var(--sc-color-secondary-25-rgb), 0.25);
  .sc-color-secondary-25 {
    color: var(--sc-color-secondary-25-rgba);
  }
  .sc-color-secondary-25-bg {
    background-color: var(--sc-color-secondary-25-rgba);
    --background: var(--sc-color-secondary-25-rgba);
  }

  // white
  --sc-color-white-100-hex: #ffffff;
  --sc-color-white-100-rgb: 255, 255, 255;
  --sc-color-white-100-rgba: rgba(var(--sc-color-white-100-rgb), 1);
  .sc-color-white-100 {
    color: var(--sc-color-white-100-rgba);
  }
  .sc-color-white-100-bg {
    background-color: var(--sc-color-white-100-rgba);
    --background: var(--sc-color-white-100-rgba);
  }

  --sc-color-white-80-hex: #ffffff;
  --sc-color-white-80-rgb: 255, 255, 255;
  --sc-color-white-80-rgba: rgba(var(--sc-color-white-80-rgb), 0.8);
  .sc-color-white-80 {
    color: var(--sc-color-white-80-rgba);
  }
  .sc-color-white-80-bg {
    background-color: var(--sc-color-white-80-rgba);
    --background: var(--sc-color-white-80-rgba);
  }

  --sc-color-white-75-hex: #ffffff;
  --sc-color-white-75-rgb: 255, 255, 255;
  --sc-color-white-75-rgba: rgba(var(--sc-color-white-75-rgb), 0.75);
  .sc-color-white-75 {
    color: var(--sc-color-white-75-rgba);
  }
  .sc-color-white-75-bg {
    background-color: var(--sc-color-white-75-rgba);
    --background: var(--sc-color-white-75-rgba);
  }

  --sc-color-white-50-hex: #ffffff;
  --sc-color-white-50-rgb: 255, 255, 255;
  --sc-color-white-50-rgba: rgba(var(--sc-color-white-50-rgb), 0.5);
  .sc-color-white-50 {
    color: var(--sc-color-white-50-rgba);
  }
  .sc-color-white-50-bg {
    background-color: var(--sc-color-white-50-rgba);
    --background: var(--sc-color-white-50-rgba);
  }

  --sc-color-white-25-hex: #ffffff;
  --sc-color-white-25-rgb: 255, 255, 255;
  --sc-color-white-25-rgba: rgba(var(--sc-color-white-25-rgb), 0.25);
  .sc-color-white-25 {
    color: var(--sc-color-white-25-rgba);
  }
  .sc-color-white-25-bg {
    background-color: var(--sc-color-white-25-rgba);
    --background: var(--sc-color-white-25-rgba);
  }

  --sc-color-white-10-hex: #ffffff;
  --sc-color-white-10-rgb: 255, 255, 255;
  --sc-color-white-10-rgba: rgba(var(--sc-color-white-10-rgb), 0.1);
  .sc-color-white-10 {
    color: var(--sc-color-white-10-rgba);
  }
  .sc-color-white-10-bg {
    background-color: var(--sc-color-white-10-rgba);
    --background: var(--sc-color-white-10-rgba);
  }

  // black
  --sc-color-black-100-hex: #0e111a;
  --sc-color-black-100-rgb: 14, 17, 26;
  --sc-color-black-100-rgba: rgba(var(--sc-color-black-100-rgb), 1);
  .sc-color-black-100 {
    color: var(--sc-color-black-100-rgba);
  }
  .sc-color-black-100-bg {
    background-color: var(--sc-color-black-100-rgba);
    --background: var(--sc-color-black-100-rgba);
  }

  --sc-color-black-75-hex: #343841;
  --sc-color-black-75-rgb: 52, 56, 65;
  --sc-color-black-75-rgba: rgba(var(--sc-color-black-75-rgb), 1);
  .sc-color-black-75 {
    color: var(--sc-color-black-75-rgba);
  }
  .sc-color-black-75-bg {
    background-color: var(--sc-color-black-75-rgba);
    --background: var(--sc-color-black-75-rgba);
  }

  --sc-color-black-50-hex: #4f545f;
  --sc-color-black-50-rgb: 79, 84, 95;
  --sc-color-black-50-rgba: rgba(var(--sc-color-black-50-rgb), 1);
  .sc-color-black-50 {
    color: var(--sc-color-black-50-rgba);
  }
  .sc-color-black-50-bg {
    background-color: var(--sc-color-black-50-rgba);
    --background: var(--sc-color-black-50-rgba);
  }

  --sc-color-black-25-hex: #8c919b;
  --sc-color-black-25-rgb: 140, 145, 155;
  --sc-color-black-25-rgba: rgba(var(--sc-color-black-25-rgb), 1);
  .sc-color-black-25 {
    color: var(--sc-color-black-25-rgba);
  }
  .sc-color-black-25-bg {
    background-color: var(--sc-color-black-25-rgba);
    --background: var(--sc-color-black-25-rgba);
  }

  // cool grey
  --sc-color-cool-grey-100-hex: #bfc5d0;
  --sc-color-cool-grey-100-rgb: 191, 197, 208;
  --sc-color-cool-grey-100-rgba: rgba(var(--sc-color-cool-grey-100-rgb), 1);
  .sc-color-cool-grey-100 {
    color: var(--sc-color-cool-grey-100-rgba);
  }
  .sc-color-cool-grey-100-bg {
    background-color: var(--sc-color-cool-grey-100-rgba);
    --background: var(--sc-color-cool-grey-100-rgba);
  }

  --sc-color-cool-grey-75-hex: #ced3dc;
  --sc-color-cool-grey-75-rgb: 206, 211, 220;
  --sc-color-cool-grey-75-rgba: rgba(var(--sc-color-cool-grey-75-rgb), 1);
  .sc-color-cool-grey-75 {
    color: var(--sc-color-cool-grey-75-rgba);
  }
  .sc-color-cool-grey-75-bg {
    background-color: var(--sc-color-cool-grey-75-rgba);
    --background: var(--sc-color-cool-grey-75-rgba);
  }

  --sc-color-cool-grey-50-hex: #e6e9ef;
  --sc-color-cool-grey-50-rgb: 230, 233, 239;
  --sc-color-cool-grey-50-rgba: rgba(var(--sc-color-cool-grey-50-rgb), 1);
  .sc-color-cool-grey-50 {
    color: var(--sc-color-cool-grey-50-rgba);
  }
  .sc-color-cool-grey-50-bg {
    background-color: var(--sc-color-cool-grey-50-rgba);
    --background: var(--sc-color-cool-grey-50-rgba);
  }

  --sc-color-cool-grey-25-hex: #f8f9fd;
  --sc-color-cool-grey-25-rgb: 248, 249, 253;
  --sc-color-cool-grey-25-rgba: rgba(var(--sc-color-cool-grey-25-rgb), 1);
  .sc-color-cool-grey-25 {
    color: var(--sc-color-cool-grey-25-rgba);
  }
  .sc-color-cool-grey-25-bg {
    background-color: var(--sc-color-cool-grey-25-rgba);
    --background: var(--sc-color-cool-grey-25-rgba);
  }

  // accents
  --sc-color-accent-orange-100-hex: #fe7726;
  --sc-color-accent-orange-100-rgb: 254, 119, 38;
  --sc-color-accent-orange-100-rgba: rgba(var(--sc-color-accent-orange-100-rgb), 1);
  .sc-color-accent-orange-100 {
    color: var(--sc-color-accent-orange-100-rgba);
  }
  .sc-color-accent-orange-100-bg {
    background-color: var(--sc-color-accent-orange-100-rgba);
    --background: var(--sc-color-accent-orange-100-rgba);
  }

  --sc-color-accent-orange-25-hex: #ffeadb;
  --sc-color-accent-orange-25-rgb: 255, 234, 219;
  --sc-color-accent-orange-25-rgba: rgba(var(--sc-color-accent-orange-25-rgb), 1);
  .sc-color-accent-orange-25 {
    color: var(--sc-color-accent-orange-25-rgba);
  }
  .sc-color-accent-orange-25-bg {
    background-color: var(--sc-color-accent-orange-25-rgba);
    --background: var(--sc-color-accent-orange-25-rgba);
  }

  --sc-color-accent-blue-100-hex: #3742fb;
  --sc-color-accent-blue-100-rgb: 55, 66, 251;
  --sc-color-accent-blue-100-rgba: rgba(var(--sc-color-accent-blue-100-rgb), 1);
  .sc-color-accent-blue-100 {
    color: var(--sc-color-accent-blue-100-rgba);
  }
  .sc-color-accent-blue-100-bg {
    background-color: var(--sc-color-accent-blue-100-rgba);
    --background: var(--sc-color-accent-blue-100-rgba);
  }

  --sc-color-accent-blue-25-hex: #dfeaff;
  --sc-color-accent-blue-25-rgb: 223, 234, 255;
  --sc-color-accent-blue-25-rgba: rgba(var(--sc-color-accent-blue-25-rgb), 1);
  .sc-color-accent-blue-25 {
    color: var(--sc-color-accent-blue-25-rgba);
  }
  .sc-color-accent-blue-25-bg {
    background-color: var(--sc-color-accent-blue-25-rgba);
    --background: var(--sc-color-accent-blue-25-rgba);
  }

  --sc-color-accent-1-hex: #a06dfa;
  --sc-color-accent-1-rgb: 160, 109, 250;
  --sc-color-accent-1-rgba: rgba(var(--sc-color-accent-1-rgb), 1);
  .sc-color-accent-1 {
    color: var(--sc-color-accent-1-rgba);
  }
  .sc-color-accent-1-bg {
    background-color: var(--sc-color-accent-1-rgba);
    --background: var(--sc-color-accent-1-rgba);
  }

  --sc-color-accent-2-hex: #2ccdca;
  --sc-color-accent-2-rgb: 44, 205, 202;
  --sc-color-accent-2-rgba: rgba(var(--sc-color-accent-2-rgb), 1);
  .sc-color-accent-2 {
    color: var(--sc-color-accent-2-rgba);
  }
  .sc-color-accent-2-bg {
    background-color: var(--sc-color-accent-2-rgba);
    --background: var(--sc-color-accent-2-rgba);
  }

  --sc-color-accent-3-hex: #6613f6;
  --sc-color-accent-3-rgb: 102, 19, 246;
  --sc-color-accent-3-rgba: rgba(var(--sc-color-accent-3-rgb), 1);
  .sc-color-accent-3 {
    color: var(--sc-color-accent-3-rgba);
  }
  .sc-color-accent-3-bg {
    background-color: var(--sc-color-accent-3-rgba);
    --background: var(--sc-color-accent-3-rgba);
  }

  --sc-color-accent-4-hex: #209795;
  --sc-color-accent-4-rgb: 32, 151, 149;
  --sc-color-accent-4-rgba: rgba(var(--sc-color-accent-4-rgb), 1);
  .sc-color-accent-4 {
    color: var(--sc-color-accent-4-rgba);
  }
  .sc-color-accent-4-bg {
    background-color: var(--sc-color-accent-4-rgba);
    --background: var(--sc-color-accent-4-rgba);
  }

  --sc-color-accent-5-hex: #ccb0fc;
  --sc-color-accent-5-rgb: 204, 176, 252;
  --sc-color-accent-5-rgba: rgba(var(--sc-color-accent-5-rgb), 1);
  .sc-color-accent-5 {
    color: var(--sc-color-accent-5-rgba);
  }
  .sc-color-accent-5-bg {
    background-color: var(--sc-color-accent-5-rgba);
    --background: var(--sc-color-accent-5-rgba);
  }

  --sc-color-accent-6-hex: #abedec;
  --sc-color-accent-6-rgb: 171, 237, 236;
  --sc-color-accent-6-rgba: rgba(var(--sc-color-accent-6-rgb), 1);
  .sc-color-accent-6 {
    color: var(--sc-color-accent-6-rgba);
  }
  .sc-color-accent-6-bg {
    background-color: var(--sc-color-accent-6-rgba);
    --background: var(--sc-color-accent-6-rgba);
  }

  --sc-color-accent-7-hex: #ff9960;
  --sc-color-accent-7-rgb: 255, 153, 96;
  --sc-color-accent-7-rgba: rgba(var(--sc-color-accent-7-rgb), 1);
  .sc-color-accent-7 {
    color: var(--sc-color-accent-7-rgba);
  }
  .sc-color-accent-7-bg {
    background-color: var(--sc-color-accent-7-rgba);
    --background: var(--sc-color-accent-7-rgba);
  }

  --sc-color-accent-8-hex: #3a4cf2;
  --sc-color-accent-8-rgb: 58, 76, 242;
  --sc-color-accent-8-rgba: rgba(var(--sc-color-accent-8-rgb), 1);
  .sc-color-accent-8 {
    color: var(--sc-color-accent-8-rgba);
  }
  .sc-color-accent-8-bg {
    background-color: var(--sc-color-accent-8-rgba);
    --background: var(--sc-color-accent-8-rgba);
  }

  --sc-color-accent-9-hex: #f269ec;
  --sc-color-accent-9-rgb: 242, 105, 236;
  --sc-color-accent-9-rgba: rgba(var(--sc-color-accent-9-rgb), 1);
  .sc-color-accent-9 {
    color: var(--sc-color-accent-9-rgba);
  }
  .sc-color-accent-9-bg {
    background-color: var(--sc-color-accent-9-rgba);
    --background: var(--sc-color-accent-9-rgba);
  }

  --sc-color-accent-10-hex: #79beef;
  --sc-color-accent-10-rgb: 121, 190, 239;
  --sc-color-accent-10-rgba: rgba(var(--sc-color-accent-10-rgb), 1);
  .sc-color-accent-10 {
    color: var(--sc-color-accent-10-rgba);
  }
  .sc-color-accent-10-bg {
    background-color: var(--sc-color-accent-10-rgba);
    --background: var(--sc-color-accent-10-rgba);
  }

  --sc-color-accent-11-hex: #6fe98a;
  --sc-color-accent-11-rgb: 111, 233, 138;
  --sc-color-accent-11-rgba: rgba(var(--sc-color-accent-11-rgb), 1);
  .sc-color-accent-11 {
    color: var(--sc-color-accent-11-rgba);
  }
  .sc-color-accent-11-bg {
    background-color: var(--sc-color-accent-11-rgba);
    --background: var(--sc-color-accent-11-rgba);
  }

  --sc-color-accent-12-hex: #37da58;
  --sc-color-accent-12-rgb: 55, 218, 88;
  --sc-color-accent-12-rgba: rgba(var(--sc-color-accent-12-rgb), 1);
  .sc-color-accent-12 {
    color: var(--sc-color-accent-12-rgba);
  }
  .sc-color-accent-12-bg {
    background-color: var(--sc-color-accent-12-rgba);
    --background: var(--sc-color-accent-12-rgba);
  }

  // states
  --sc-color-state-success-hex: #11c182;
  --sc-color-state-success-rgb: 17, 193, 130;
  --sc-color-state-success-rgba: rgba(var(--sc-color-state-success-rgb), 1);
  .sc-color-state-success {
    color: var(--sc-color-state-success-rgba);
  }

  --sc-color-state-success-bg-hex: #e8f9f3;
  --sc-color-state-success-bg-rgb: 232, 249, 243;
  --sc-color-state-success-bg-rgba: rgba(var(--sc-color-state-success-bg-rgb), 1);
  .sc-color-state-success-bg {
    background-color: var(--sc-color-state-success-bg-rgba);
    --background: var(--sc-color-state-success-bg-rgba);
  }

  --sc-color-state-error-hex: #f64141;
  --sc-color-state-error-rgb: 246, 65, 65;
  --sc-color-state-error-rgba: rgba(var(--sc-color-state-error-rgb), 1);
  .sc-color-state-error {
    color: var(--sc-color-state-error-rgba);
  }

  --sc-color-state-error-bg-hex: #feecec;
  --sc-color-state-error-bg-rgb: 254, 236, 236;
  --sc-color-state-error-bg-rgba: rgba(var(--sc-color-state-error-bg-rgb), 1);
  .sc-color-state-error-bg {
    background-color: var(--sc-color-state-error-bg-rgba);
    --background: var(--sc-color-state-error-bg-rgba);
  }

  --sc-color-state-info-hex: #2e5aac;
  --sc-color-state-info-rgb: 46, 90, 172;
  --sc-color-state-info-rgba: rgba(var(--sc-color-state-info-rgb), 1);
  .sc-color-state-info {
    color: var(--sc-color-state-info-rgba);
  }

  --sc-color-state-info-bg-hex: #eef2fa;
  --sc-color-state-info-bg-rgb: 238, 242, 250;
  --sc-color-state-info-bg-rgba: rgba(var(--sc-color-state-info-bg-rgb), 1);
  .sc-color-state-info-bg {
    background-color: var(--sc-color-state-info-bg-rgba);
    --background: var(--sc-color-state-info-bg-rgba);
  }

  --sc-color-state-warning-hex: #fbcc26;
  --sc-color-state-warning-rgb: 251, 204, 38;
  --sc-color-state-warning-rgba: rgba(var(--sc-color-state-warning-rgb), 1);
  .sc-color-state-warning {
    color: var(--sc-color-state-warning-rgba);
  }

  --sc-color-state-warning-bg-hex: #fffaea;
  --sc-color-state-warning-bg-rgb: 255, 250, 234;
  --sc-color-state-warning-bg-rgba: rgba(var(--sc-color-state-warning-bg-rgb), 1);
  .sc-color-state-warning-bg {
    background-color: var(--sc-color-state-warning-bg-rgba);
    --background: var(--sc-color-state-warning-bg-rgba);
  }

  // Descript
  --sc-color-descript-hex: #0062ff;
  --sc-color-descript-rgb: 0, 98, 255;
  --sc-color-descript-rgba: rgba(var(--sc-color-descript-rgb), 1);
  .sc-color-descript {
    color: var(--sc-color-descript-rgba);
  }
  .sc-color-descript-bg {
    background-color: var(--sc-color-descript-rgba);
    --background: var(--sc-color-descript-rgba);
  }
}
