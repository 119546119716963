.toast-container {
  & > * {
    max-width: 93vw;
  }

  .ngx-toastr {
    background: var(--sc-color-white-100-hex);
    display: block;
    padding: 16px;
    margin: 0 0 10px 0;
    border-radius: 8px;
    overflow: hidden;
    width: 400px;
    color: unset;

    transition: box-shadow 200ms ease-in-out;
    box-shadow: 0px 2px 10px rgba(107, 112, 118, 0.2);
    &:hover {
      box-shadow: 0px 2px 10px rgba(107, 112, 118, 0.4);
    }
  }

  .ngx-toastr.toast-info {
    ion-progress-bar.accent-progress-bar {
      --progress-background: var(--sc-color-accent-1-hex); // toast progress bar
    }
  }

  .ngx-toastr.toast-success {
    ion-progress-bar.accent-progress-bar {
      --progress-background: var(--sc-color-primary-100-hex); // toast progress bar
    }
  }

  .ngx-toastr.toast-warning {
    ion-progress-bar.accent-progress-bar {
      --progress-background: var(--sc-color-state-warning-hex); // toast progress bar
    }
  }

  .ngx-toastr.toast-error {
    ion-progress-bar.accent-progress-bar {
      --progress-background: var(--sc-color-state-error-hex); // toast progress bar
    }
  }

  ion-progress-bar.accent-progress-bar, ion-progress-bar.option-progress-bar {
    position: absolute;
    z-index: 500;
    top: 0;
    left: 0;
    border-radius: 8px;
    --background: transparent;
    --progress-background: rgba(var(--sc-color-black-100-rgb), 0.3); // toast progress bar
  }

  .toast-title {
    margin-top: 0;
  }

  ion-button#close-button {
    position: absolute;
    top: 4px;
    right: 4px;
    margin: 0;
    border-radius: 50%;
    --border-radius: 50%;
    transition: background-color 200ms ease-in-out;

    ion-icon {
      color: rgba(var(--sc-color-black-100-rgb), 0.5);
    }

    &:hover {
      background-color: rgba(var(--sc-color-black-100-rgb), 0.05);
      ion-icon {
        color: var(--sc-color-black-100-hex);
      }
    }
  }

  #content {
    margin-right: 36px;
  }
}

.in-studio {
  .toast-container.toast-bottom-right {
    right: 21px;
    bottom: 56px;
  }
}

.dark-theme {
  .toast-container {
    .ngx-toastr {
      background: var(--sc-color-secondary-dark-mode-bg-hex);
      color: var(--sc-color-white-100-hex);
      box-shadow: 0px 2px 10px rgba(6, 11, 28, 0.5);

      &:hover {
        box-shadow: 0px 2px 10px rgba(6, 11, 28, 1);
      }
    }

    ion-button#close-button {
      ion-icon {
        color: rgba(var(--sc-color-white-100-rgb), 0.5);
      }

      &:hover {
        background-color: rgba(var(--sc-color-white-100-rgb), 0.05);
        ion-icon {
          color: var(--sc-color-white-100-hex);
        }
      }
    }
  }
}
@media screen and (max-width: 575px) {
  .in-studio {
    .toast-container.toast-bottom-right {
      right: 12px;
      bottom: 94px;
    }
  }
}