// http://ionicframework.com/docs/theming/
@import '@ionic/angular/css/core.css';
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';

@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';
@import '@ionic/angular/css/display.css';

@import 'ngx-toastr/toastr';
@import '@ng-select/ng-select/themes/default.theme.css';
@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/themes/light.css';
@import 'tippy.js/animations/shift-away.css';
@import url(http://fonts.googleapis.com/css?family=Roboto:500);

@import './animations.scss';
@import './buttons.scss';
@import './colors.scss';
@import './inputs.scss';
@import './menus.scss';
@import './modals.scss';
@import './notifications.scss';
@import './toasts.scss';
@import './tours.scss';
@import './typography.scss';

:root {
  --ion-backdrop-opacity: 0.3;
  --box-shadow: 0px 5px 10px rgba(107, 112, 118, 0.2);
}

.flex {
  display: flex;
}

.scroll {
  height: calc(var(--app-height) - 81px);
  overflow-y: auto;
}

mat-progress-spinner.mat-mdc-progress-spinner .mdc-circular-progress__determinate-circle,
.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--sc-color-primary-100-hex);
}

ion-spinner {
  --color: var(--sc-color-primary-100-hex);
}

ion-grid {
  padding: 0;
}

ion-content {
  --padding-bottom: 0;
  --padding-end: 0;
  --padding-start: 0;
  --padding-top: 0;
}

ion-card.pull-up {
  margin-top: -8.5vh;
}

.item-label-stacked ion-input.sc-ion-input-md-h[type='text'] {
  --padding-bottom: 13px;
  --padding-top: 11px;
}

ion-accordion {
  background-color: transparent;

  ion-button + ion-icon.ion-accordion-toggle-icon {
    margin-inline-start: 8px;
  }

  ion-icon.ion-accordion-toggle-icon {
    color: var(--sc-color-black-25-hex);
  }

  &.accordion-expanding,
  &.accordion-expanded {
    ion-icon.ion-accordion-toggle-icon {
      color: var(--sc-color-black-50-hex);
    }
  }
}

ion-toggle {
  height: 24px;
  width: 48px;
  min-width: 48px;
  --handle-width: 20px;
  --handle-spacing: 1px;
  --background-checked: var(--sc-color-primary-100-hex);
  --handle-background-checked: var(--sc-color-white-100-hex);
}

ion-item {
  --background: transparent;
  --background-activated: transparent;
  --background-focused: transparent;
  --highlight-color-valid: transparent;
  --ion-item-border-color: var(--sc-color-cool-grey-75-hex);
}

ion-item ion-icon {
  color: var(--sc-color-black-75-rgba);
}

ion-menu#learn-the-basics {
  --width: 400px;
}

.sticky-blur-header {
  box-shadow: 0 2px 2px var(--sc-color-cool-grey-25-hex);
  background-color: rgba(var(--ion-background-color-rgb), 0.5);
  backdrop-filter: blur(8px);
  position: sticky;
  top: 0;
  z-index: 499;
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important; /* 2 */
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; /* 3 */
}

[hidden] {
  display: none !important;
}

.autoWidth {
  --width: auto;
  width: auto;
}

.grecaptcha-badge {
  visibility: hidden;
}

// Focus styling for accessibility
*:focus-visible,
*.ion-focused,
ion-toggle:focus,
ion-select:focus-visible {
  outline: 4px solid var(--sc-color-primary-100-hex);
  border-radius: 8px;
}

ion-checkbox {
  margin: 2px;
}

ion-checkbox:focus {
  outline: 2px solid var(--sc-color-primary-100-hex);
  border-radius: 4px;
}

ion-badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  --padding-bottom: 2px;
}

ion-progress-bar::part(stream) {
  background-image: radial-gradient(
    ellipse at center,
    var(--sc-color-secondary-100-rgba) 0%,
    var(--sc-color-secondary-100-rgba) 30%,
    transparent 30%
  );
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--sc-color-cool-grey-75-hex) var(--sc-color-secondary-light-mode-bg-rgba);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}
::-webkit-resizer {
  border: 9px solid var(--sc-color-secondary-light-mode-bg-rgba);
  border-bottom-color: var(--sc-color-cool-grey-100-hex);
  border-right-color: var(--sc-color-cool-grey-100-hex);
}

*::-webkit-scrollbar-track {
  background: var(--sc-color-secondary-light-mode-bg-rgba);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--sc-color-cool-grey-75-hex);
  border-radius: 20px;
  border: 3px solid var(--sc-color-secondary-light-mode-bg-rgba);
}

.dark-theme {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  pre,
  ul {
    color: var(--sc-color-white-100-rgba);
  }

  ion-accordion.accordion-expanding,
  ion-accordion.accordion-expanded {
    ion-icon.ion-accordion-toggle-icon {
      color: var(--sc-color-white-75-hex);
    }
  }

  ion-item {
    --ion-item-border-color: var(--sc-color-black-75-hex);
  }

  ion-item.item-interactive {
    --border-color: var(--sc-color-white-50-rgba);
  }

  ion-item ion-icon {
    color: var(--sc-color-white-75-rgba);
  }

  .sticky-blur-header {
    box-shadow: 0 2px 2px var(--sc-color-secondary-dark-mode-bg-rgba);
  }

  /* Works on Firefox */
  * {
    scrollbar-color: var(--sc-color-white-10-rgba) var(--sc-color-secondary-dark-mode-bg-rgba) !important;
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar-track {
    background: var(--sc-color-secondary-dark-mode-bg-rgba) !important;
  }

  *::-webkit-scrollbar-thumb {
    background-color: var(--sc-color-white-10-rgba);
    border: 3px solid var(--sc-color-secondary-dark-mode-bg-rgba) !important;
  }

  ::-webkit-resizer {
    border: 9px solid var(--sc-color-secondary-dark-mode-bg-rgba) !important;
    border-bottom-color: var(--sc-color-light-dark-mode-bg-rgba) !important;
    border-right-color: var(--sc-color-light-dark-mode-bg-rgba) !important;
  }
}
