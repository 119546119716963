sc-notifications .content {
  font-size: 17px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 12px;

  p {
    margin: 12px 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 12px 0;

    li {
      margin: 8px 0;
      display: flex;

      &::before {
        content: '';
        margin-right: 8px;
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url('../assets/images/Checkbox-Pink.png') no-repeat center;
        background-size: contain;
      }
    }
  }

  a {
    color: var(--sc-color-secondary-100-hex);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  & > *:last-child {
    margin-bottom: 0;
  }
}
