:root {
	.dark-theme {
		--ion-background-color: var(--sc-color-main-dark-mode-bg-rgba);
		--ion-background-color-rgb: var(--sc-color-main-dark-mode-bg-rgb);

		--ion-text-color: var(--sc-color-white-100-rgba);
		--ion-text-color-rgb: var(--sc-color-white-100-rgb);


		--ion-color-light: var(--sc-color-main-dark-mode-bg-rgba);
		--ion-color-light-rgb: var(--sc-color-main-dark-mode-bg-rgb);
		--ion-color-light-contrast: var(--sc-color-white-100-rgba);
		--ion-color-light-contrast-rgb: var(--sc-color-white-100-rgb);
		--ion-color-light-shade: var(--sc-color-main-dark-mode-bg-rgba);
		--ion-color-light-tint: var(--sc-color-main-dark-mode-bg-rgba);

		.ion-color-light {
			--ion-color-base: var(--ion-color-light) !important;
			--ion-color-base-rgb: var(--ion-color-light-rgb) !important;
			--ion-color-contrast: var(--ion-color-light-contrast) !important;
			--ion-color-contrast-rgb: var(--ion-color-light-contrast-rgb) !important;
			--ion-color-shade: var(--ion-color-dark-shade) !important;
			--ion-color-tint: var(--ion-color-light-tint) !important;
		}


		--ion-color-medium: var(--sc-color-cool-grey-100-rgba);
		--ion-color-medium-rgb: var(--sc-color-cool-grey-100-rgb);
		--ion-color-medium-contrast: var(--sc-color-black-100-rgba);
		--ion-color-medium-contrast-rgb: var(--sc-color-black-100-rgb);
		--ion-color-medium-shade: var(--sc-color-cool-grey-100-rgba);
		--ion-color-medium-tint: var(--sc-color-cool-grey-100-rgba);

		.ion-color-medium {
			--ion-color-base: var(--ion-color-medium) !important;
			--ion-color-base-rgb: var(--ion-color-medium-rgb) !important;
			--ion-color-contrast: var(--ion-color-medium-contrast) !important;
			--ion-color-contrast-rgb: var(--ion-color-medium-contrast-rgb) !important;
			--ion-color-shade: var(--ion-color-medium-shade) !important;
			--ion-color-tint: var(--ion-color-medium-tint) !important;
		}


		--ion-color-dark: var(--sc-color-main-light-mode-bg-rgba);
		--ion-color-dark-rgb: var(--sc-color-main-light-mode-bg-rgb);
		--ion-color-dark-contrast: var(--sc-color-black-100-rgba);
		--ion-color-dark-contrast-rgb: var(--sc-color-black-100-rgb);
		--ion-color-dark-shade: var(--sc-color-main-light-mode-bg-rgba);
		--ion-color-dark-tint: var(--sc-color-main-light-mode-bg-rgba);

		.ion-color-dark {
			--ion-color-base: var(--ion-color-dark) !important;
			--ion-color-base-rgb: var(--ion-color-dark-rgb) !important;
			--ion-color-contrast: var(--ion-color-dark-contrast) !important;
			--ion-color-contrast-rgb: var(--ion-color-dark-contrast-rgb) !important;
			--ion-color-shade: var(--ion-color-dark-shade) !important;
			--ion-color-tint: var(--ion-color-dark-tint) !important;
		}


		--ion-color-primary: var(--sc-color-primary-110-rgba);
		--ion-color-primary-rgb: var(--sc-color-primary-110-rgb);
		--ion-color-primary-contrast: var(--sc-color-white-100-rgba);
		--ion-color-primary-contrast-rgb: var(--sc-color-white-100-rgb);
		--ion-color-primary-shade: var(--sc-color-primary-110-rgba);
		--ion-color-primary-tint: var(--sc-color-primary-110-rgba);

		.ion-color-primary {
			--ion-color-base: var(--ion-color-primary) !important;
			--ion-color-base-rgb: var(--ion-color-primary-rgb) !important;
			--ion-color-contrast: var(--ion-color-primary-contrast) !important;
			--ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb) !important;
			--ion-color-shade: var(--ion-color-primary-shade) !important;
			--ion-color-tint: var(--ion-color-primary-tint) !important;
		}


		--ion-color-secondary: var(--sc-color-secondary-100-rgba);
		--ion-color-secondary-rgb: var(--sc-color-secondary-100-rgb);
		--ion-color-secondary-contrast: var(--sc-color-white-100);
		--ion-color-secondary-contrast-rgb: var(--sc-color-white-100-rgb);
		--ion-color-secondary-shade: var(--sc-color-secondary-100-rgba);
		--ion-color-secondary-tint: var(--sc-color-secondary-100-rgba);

		.ion-color-secondary {
			--ion-color-base: var(--ion-color-secondary) !important;
			--ion-color-base-rgb: var(--ion-color-secondary-rgb) !important;
			--ion-color-contrast: var(--ion-color-secondary-contrast) !important;
			--ion-color-contrast-rgb: var(--ion-color-secondary-contrast-rgb) !important;
			--ion-color-shade: var(--ion-color-secondary-shade) !important;
			--ion-color-tint: var(--ion-color-secondary-tint) !important;
		}


		--ion-color-tertiary: var(--sc-color-secondary-100-rgba);
		--ion-color-tertiary-rgb: var(--sc-color-secondary-100-rgb);
		--ion-color-tertiary-contrast: var(--sc-color-white-100);
		--ion-color-tertiary-contrast-rgb: var(--sc-color-white-100-rgb);
		--ion-color-tertiary-shade: var(--sc-color-secondary-100-rgba);
		--ion-color-tertiary-tint: var(--sc-color-secondary-100-rgba);

		.ion-color-tertiary {
			--ion-color-base: var(--ion-color-tertiary) !important;
			--ion-color-base-rgb: var(--ion-color-tertiary-rgb) !important;
			--ion-color-contrast: var(--ion-color-tertiary-contrast) !important;
			--ion-color-contrast-rgb: var(--ion-color-tertiary-contrast-rgb) !important;
			--ion-color-shade: var(--ion-color-tertiary-shade) !important;
			--ion-color-tint: var(--ion-color-tertiary-tint) !important;
		}


		--ion-color-success: var(--sc-color-state-success-rgba);
		--ion-color-success-rgb: var(--sc-color-state-success-rgb);
		--ion-color-success-contrast: var(--sc-color-white-rgba);
		--ion-color-success-contrast-rgb: var(--sc-color-white-rgb);
		--ion-color-success-shade: var(--sc-color-state-success-rgba);
		--ion-color-success-tint: var(--sc-color-state-success-rgba);

		.ion-color-success {
			--ion-color-base: var(--ion-color-success) !important;
			--ion-color-base-rgb: var(--ion-color-success-rgb) !important;
			--ion-color-contrast: var(--ion-color-success-contrast) !important;
			--ion-color-contrast-rgb: var(--ion-color-success-contrast-rgb) !important;
			--ion-color-shade: var(--ion-color-success-shade) !important;
			--ion-color-tint: var(--ion-color-success-tint) !important;
		}


		--ion-color-warning: var(--sc-color-state-warning-rgba);
		--ion-color-warning-rgb: var(--sc-color-state-warning-rgb);
		--ion-color-warning-contrast: var(--sc-color-black-rgba);
		--ion-color-warning-contrast-rgb: var(--sc-color-black-rgb);
		--ion-color-warning-shade: var(--sc-color-state-warning-rgba);
		--ion-color-warning-tint: var(--sc-color-state-warning-rgba);

		.ion-color-warning {
			--ion-color-base: var(--ion-color-warning) !important;
			--ion-color-base-rgb: var(--ion-color-warning-rgb) !important;
			--ion-color-contrast: var(--ion-color-warning-contrast) !important;
			--ion-color-contrast-rgb: var(--ion-color-warning-contrast-rgb) !important;
			--ion-color-shade: var(--ion-color-warning-shade) !important;
			--ion-color-tint: var(--ion-color-warning-tint) !important;
		}


		--ion-color-danger: var(--sc-color-state-error-rgba);
		--ion-color-danger-rgb: var(--sc-color-state-error-rgb);
		--ion-color-danger-contrast: var(--sc-color-white-rgba);
		--ion-color-danger-contrast-rgb: var(--sc-color-white-rgb);
		--ion-color-danger-shade: var(--sc-color-state-error-rgba);
		--ion-color-danger-tint: var(--sc-color-state-error-rgba);

		.ion-color-danger {
			--ion-color-base: var(--ion-color-danger) !important;
			--ion-color-base-rgb: var(--ion-color-danger-rgb) !important;
			--ion-color-contrast: var(--ion-color-danger-contrast) !important;
			--ion-color-contrast-rgb: var(--ion-color-danger-contrast-rgb) !important;
			--ion-color-shade: var(--ion-color-danger-shade) !important;
			--ion-color-tint: var(--ion-color-danger-tint) !important;
		}


		// pages
		sc-dashboard {
			#upcoming-favorites {
				border-color: var(--sc-color-secondary-dark-mode-bg-rgba);

				ion-header ion-toolbar {
					--border-color: var(--sc-color-secondary-dark-mode-bg-rgba) !important;
				}
			}
		}

		// components
		sc-about {
			h2 {
				color: var(--sc-color-white-75-rgba);
			}
		}

		sc-header-bar {
			ion-toolbar {
				--border-color: var(--sc-color-secondary-dark-mode-bg-rgba);

				h2 {
					color: var(--sc-color-white-50-rgba);
				}
			}
		}

		sc-members {
			ion-row.border {
				border-bottom: 1px solid var(--sc-color-secondary-dark-mode-bg-rgba);
			}
		}

		sc-search-bar {
			ion-toolbar {
				--border-color: var(--sc-color-secondary-dark-mode-bg-rgba);

				ion-item ion-button ion-icon {
					color: var(--sc-color-white-100-rgba) !important;
				}
			}
		}

		sc-session {
			ion-card {
				border-color: transparent;

				ion-card-content {
					background-color: var(--sc-color-secondary-dark-mode-bg-rgba);
				}

				#bottom-buttons {
					background-color: var(--sc-color-white-25-rgba);
					border-color: transparent;
				}
			}
		}

		sc-shows {
			ion-row.border {
				border-bottom: 1px solid var(--sc-color-secondary-dark-mode-bg-rgba);
			}

			.show ion-img {
				border-color: var(--sc-color-lighter-dark-mode-bg-rgba);
			}

			.show ion-img:hover {
				border: 1px solid var(--sc-color-secondary-100-rgba);
			}
		}

		sc-shows-list {
			ion-img {
				border-color: var(--sc-color-lighter-dark-mode-bg-rgba);
			}

			ion-img:hover {
				border: 1px solid var(--sc-color-secondary-100-rgba);
			}
		}

		sc-shows-nav {
			#shows-nav {
			  	background-color: var(--sc-color-secondary-dark-mode-bg-rgba);
			}

			.show-list-item,
			.organization-list-item {
				ion-img {
					border-color: var(--sc-color-lighter-dark-mode-bg-rgba);
				}
			}
		}
	}
}
