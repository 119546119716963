@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(var(--ion-color-knowledge-rgb), 0.8);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes ping {
  75%,
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes bounce {
  25%,
  75% {
    transform: translateY(-10%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  0%,
  50%,
  100% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

@keyframes headShake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-2px) rotateY(-9deg);
  }

  18.5% {
    transform: translateX(2px) rotateY(7deg);
  }

  31.5% {
    transform: translateX(-1px) rotateY(-5deg);
  }

  43.5% {
    transform: translateX(1px) rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fade {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@keyframes backdrop-blur-in {
  from {
    backdrop-filter: blur(0px);
  }
  to {
    backdrop-filter: blur(2px);
  }
}

@keyframes backdrop-blur-out {
  from {
    backdrop-filter: blur(2px);
  }
  to {
    backdrop-filter: blur(0px);
  }
}
@keyframes pulseUnreadChat {
  0% {
    background-color: rgba(var(--sc-color-secondary-100-rgb), 0.1);
    transform: scale(0.97);
  }
  25%,
  75% {
    background-color: rgba(var(--sc-color-secondary-100-rgb), 0.2);
    transform: scale(1);
  }
  100% {
    background-color: rgba(var(--sc-color-secondary-100-rgb), 0.1);
    transform: scale(0.97);
  }
}
