.shepherd-element {
  .shepherd-content {
    .shepherd-progress-bar {
      background-color: var(--sc-color-primary-100-rgba);
      border-radius: 10px;
      height: 5px;
      margin: 1px;
    }

    .shepherd-progress-steps {
      position: absolute;
      top: 15px;
      right: 40px;
      font-size: 13px;
      opacity: 0.4;
    }

    .shepherd-tour-name {
      color: var(--sc-color-primary-110-hex);
      margin: 12px 16px 0;
      font-size: 14px;
      font-weight: bold;
    }

    .shepherd-cancel-icon {
      position: absolute;
      top: 6px;
    }

    .shepherd-text {
      font-family: 'MoriGothic-Regular';
      font-size: 14px; // body-regular
      line-height: 150%; // body-regular
      color: var(--sc-color-black-25-rgba);
      padding-top: 0;
      padding-left: 1em;
      padding-right: 1em;
    }

    .shepherd-footer {
      padding-bottom: 10px;
      padding-top: 10px;
      display: block;
      height: 50px;
    }
  }
}

.shepherd-element.shepherd-has-title .shepherd-content {
  .shepherd-header {
    background-color: var(--sc-color-white-100-hex);
    padding-bottom: 0;

    .shepherd-title {
      font-family: 'MoriGothic-Bold';
      font-size: 16px; // h6
      color: var(--sc-color-black-100-rgba);
    }
  }
}
.shepherd-element.shepherd-has-title[data-popper-placement^='bottom'] > .shepherd-arrow:before {
  background-color: #ffffff !important;
}

.shepherd-element.centered .shepherd-content,
.shepherd-element.shepherd-has-title.centered .shepherd-content .shepherd-header {
  text-align: center;
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible {
  z-index: 500;
}

sc-chat-group #chat-box #chat-box-tour-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 150px);
  z-index: 1;
  pointer-events: none;
}
